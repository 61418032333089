import { Injectable } from '@angular/core';
import { MasterService } from 'src/app/modules/tenant/services/master.service';

@Injectable({
  providedIn: 'root'
})
export class TenantMasterService {
  tenantSizeStrings: any[] = [];
  constructor(private masterService: MasterService) {
    this.tenantSizeStrings = JSON.parse(localStorage.getItem('tenantSizeStrings') || '[]');
  }

  loadTenantMasterData() {
    this.getTenantSizeString();
  }

  getTenantSizeString() {
    this.masterService.getTenantSizes().subscribe((res: any) => {
      this.tenantSizeStrings = res.data;

      localStorage.setItem('tenantSizeStrings', JSON.stringify(this.tenantSizeStrings));
    });
  }

  setTenantSizeString(tenantSizeStrings: any[]) {
    this.tenantSizeStrings = tenantSizeStrings;
    localStorage.setItem('tenantSizeStrings', JSON.stringify(this.tenantSizeStrings));
  }
}
