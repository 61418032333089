import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Validators } from '@angular/forms';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';

export interface DataList {
  id: number | string | null;
  name: string;
}

@Component({
  selector: 'autocomplete-input-comp',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutoCompleteComponent implements OnChanges, OnInit {
  @Input() options: DataList[] = [];
  @Input() labelInput?: string = '';
  @Input() control?: any = new FormControl();
  @Input() controlModel?: any = null;
  @Input() className? = 'w-[150px]';
  @Input() selectedItem? = '';
  @Input() panelWidth: number | string = 230;
  @Input() autocompleteOn: boolean = true;
  @Input() name? = '';
  @Output() handleOnChangeData: EventEmitter<number | string | null> = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  dataCtrl: FormControl<string | DataList | null> = new FormControl('');
  dataFiltered: Observable<DataList[]>;
  activeOption: DataList | null = null;
  selectedId: number | string | null = null;

  constructor(private utils: UtilsHelper) {}

  ngOnInit(): void {
    this.dataFiltered = this.dataCtrl.valueChanges.pipe(
      startWith(''),
      map((value: string | DataList | any) => (typeof value === 'string' ? value : value?.name)),
      map((name: string) => (name ? this.filterOptions(name) : this.options.slice()))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['controlModel']) {
      if (this.controlModel) {
        const initInputModel = this.options.find((option) => option.id && option.id === this.controlModel);
        if (initInputModel) {
          this.dataCtrl.setValue(initInputModel.name);
          this.activeOption = initInputModel;
          this.selectedId = initInputModel.id;
        }
      }
    }

    if (changes['options']) {
      if (!this.utils.isEqual(changes['options'].previousValue, changes['options'].currentValue)) {
        this.updateDisplayControl('ngOnChanges');
      }
    }

    if (changes['control']) {
      if (this.control) {
        this.control.valueChanges.subscribe((value: any) => {
          const initInput = this.options.find((option) => this.control && option.id === this.control.value);
          if (initInput) {
            this.dataCtrl.setValue(initInput.name);
            this.activeOption = initInput;
            this.selectedId = initInput.id;
          }
        });

        this.control.statusChanges.subscribe((status: any) => {
          if (status === 'INVALID') {
            this.dataCtrl.setValidators(this.customValidator(this.control.errors));
            this.dataCtrl.updateValueAndValidity();
          } else {
            this.dataCtrl.setValidators(this.control.validator);
            this.dataCtrl.updateValueAndValidity();
          }

          if (status === 'DISABLED') {
            this.dataCtrl.disable();
          } else {
            this.dataCtrl.enable();
          }
        });
      }
    }
  }

  private customValidator(errors: any): any {
    return (control: FormControl) => {
      return errors;
    };
  }

  private updateDisplayControl(from?: string): void {
    if (this.control?.value && this.options.length > 0) {
      const selectedOption = this.options.find((option) => option.id === this.control.value);

      if (selectedOption) {
        this.dataCtrl.setValue(selectedOption.name);
        this.activeOption = selectedOption;
        this.selectedId = selectedOption.id;
      }
    } else if (this.selectedItem) {
      this.dataCtrl.setValue(this.selectedItem, { emitEvent: false });
    }
    if (this.control?.disabled) {
      this.dataCtrl.disable();
    } else {
      this.dataCtrl.enable();
    }

    if (this.control?.validator) {
      this.dataCtrl.setValidators(this.control?.validator);
    } else {
      this.dataCtrl.clearValidators();
    }

    this.dataCtrl.updateValueAndValidity();
  }

  displayFn(option: string | DataList): string {
    return typeof option === 'string' ? option : option?.name ?? '';
  }

  private filterOptions(name: string): DataList[] {
    const filterValue = name?.toLowerCase();
    return this.options.filter((option) => option?.name?.toLowerCase().includes(filterValue));
  }

  onHandleClear(event: Event, data?: any) {
    this.dataCtrl.setValue(null);
    if (this.control) this.control.setValue(null);
    this.activeOption = null;
    this.selectedId = null;
    this.handleOnChangeData?.emit();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption: DataList = event.option.value;
    if (this.control) this.control.setValue(selectedOption.id);
    this.dataCtrl.setValue(selectedOption.name, { emitEvent: false });
    this.activeOption = selectedOption;
    this.selectedId = selectedOption.id;
    this.handleOnChangeData.emit(selectedOption.id);
  }

  onBlur(): void {
    setTimeout(() => {
      const enteredName = typeof this.dataCtrl.value === 'string' ? this.dataCtrl.value?.toLowerCase() : '';
      const isValidOption = this.options.some((option) => option.name?.toLowerCase() === enteredName);
      if (!isValidOption) {
        this.dataCtrl.setValue('');
        if (this.control) this.control.setValue(null);
        this.handleOnChangeData.emit(null);
      }
    }, 500);
  }

  openAutocomplete(): void {
    setTimeout(() => {
      if (this.autocompleteTrigger && this.activeOption) {
        const index = this.options.findIndex((ele) => ele.id === this.activeOption?.id);
        if (index >= 0) {
          this.autocompleteTrigger.autocomplete._keyManager.setActiveItem(index);
        }
      }
    }, 0);
  }
}
